import request from '@/utils/request.js';
let baseUrl
function checkBaseUrl(){
    let re1 = /details1/
    let re2 = /created1/
    if (location.href.includes('templateApp') && !re1.test(location.href) && !re2.test(location.href) && location.pathname != "/templateApp/list" && location.pathname != "/templateApp/List" || location.pathname === "/template" || location.pathname.includes('/template/created/') || location.pathname.includes('/template/details/')) {
        baseUrl = 'App'
    } else {
        baseUrl = ''
    }
}
checkBaseUrl()
//获取列表
export function getList(data) {
checkBaseUrl()
    return request().get(`/api/taskhall/v1/mode${baseUrl}/list`, { params: data })
}
//获取详情
export function getDetails(id) {
    return request().get(`/api/taskhall/v1/mode${baseUrl}/getMode`, { params: { id: id } })
}
//新建任务
export function addMode(data) {
    return request().post(`/api/taskhall/v1/mode${baseUrl}/addMode`, data)
}
//编辑任务
export function updateMode(data) {
    return request().put(`/api/taskhall/v1/mode${baseUrl}/updateMode`, data)
}
//删除编辑中的任务
export function updateStatus(id) {
    return request().delete(`/api/taskhall/v1/mode${baseUrl}/updateStatus?id=${id}`)
}
//启用任务
export function updateEnable(data) {
    return request().put(`/api/taskhall/v1/mode${baseUrl}/updateEnable?id=${data.id}&typeCode=${data.typeCode}`)
}
//复制模板 快速创建
export function copyMode(id) {
    return request().post(`/api/taskhall/v1/mode${baseUrl}/copyMode?id=${id}`,)
}

///api/taskhall/v1/modeApp/getPlatformMode  拓店任务内嵌 单独使用的页面获取平台模板
export function getPlatformMode() {
    return request().get(`/api/taskhall/v1/modeApp/getPlatformMode?typeCode=CTT000EXPANDTASK`,)
}
//拓店任务内嵌 获取详情
export function getDetailsApp(id) {
    if (location.pathname.includes('/template/created/') || location.pathname.includes('/template/details/')) {
        return request().get(`/api/taskhall/v1/mode${baseUrl}/getMode`, { params: { id: id } })
    } else {
        return request().get(`/api/taskhall/v1/mode${baseUrl}/getTenantMode`, { params: { typeCode: 'CTT000EXPANDTASK' } })
    }
}

