import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import taskHall from "../views/TaskHall.vue"
import store from "../store";
import { afterLogout } from "@/utils/requestFunction.js";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "/",
    redirect: "/taskHall/list",
    component: taskHall,
    children: []
  },
  {
    path: "/taskHall",
    redirect: "/taskHall/list",
    name: "触点任务管理",
    component: taskHall,
    meta: { hidden: false, title: "触点任务管理", },
    children: [
      {
        path: "/taskHall/taskManagement",
        redirect: "/taskHall/list",
        name: "任务管理",
        component: () => import("../views/taskManagement/TaskManagement.vue"),
        meta: { hidden: false, title: "任务管理" },
        children: [
          {
            name: "任务列表",
            path: "/taskHall/list",
            meta: { hidden: false, title: "任务列表", },
            component: () =>
              import("@/views/taskManagement/list.vue"),
          },
          {
            name: "任务详情",
            path: "/taskHall/details/:id",
            component: () =>
              import("@/views/taskManagement/details.vue"),
            meta: {
              hidden: true,
              title: "任务详情",
              key: '',
              breadcrumb: [
                { title: "触点任务运营中心" },
                { title: "任务管理", path: "/taskHall/list" },
                { title: "任务详情" },
              ],
            }
          },
          {
            name: "基础信息配置",
            path: "/taskHall/created/:id",
            component: () =>
              import("@/views/taskManagement/created.vue"),
            meta: {
              hidden: true,
              title: "基础信息配置",
              key: '',
              breadcrumb: [
                { title: "触点任务运营中心" },
                { title: "任务管理", path: "/taskHall/list" },
                { title: " 基础信息配置" },
              ],
            }
          },
        ]
      },
      {
        path: "/taskHall/audit",
        redirect: "/audit/auditList",
        name: "审核管理",
        component: () => import("../views/taskAudit/TaskAudit.vue"),
        meta: { hidden: false, title: "审核管理" },
        children: [
          {
            path: "/audit/auditList",
            name: "任务报名审核",
            component: () => import("../views/taskAudit/TaskAuditList.vue"),
            meta: { hidden: false, title: "任务报名审核" },
          },
        ]
      },
      //任务模板
      {
        path: "/template",
        name: "任务模板",
        component: () => import("@/views/template/List.vue"),
        meta: { hidden: false, title: "任务模板" },
      },
      {
        path: "/template/created/:id",
        name: "创建任务模板",
        component: () => import("@/views/templateApp/Created.vue"),
        meta: {
          hidden: true,
          title: "创建任务模板",
          key: '',
          breadcrumb: [
            { title: "触点任务运营中心" },
            { title: "任务模板", path: "/template" },
            { title: "创建任务模板" },
          ],
        }
      },
      {
        path: "/template/details/:id",
        name: "任务模板详情",
        component: () => import("@/views/templateApp/Created.vue"),
        meta: {
          hidden: true,
          title: "任务模板详情",
          key: '',
          breadcrumb: [
            { title: "触点任务运营中心" },
            { title: "任务模板", path: "/template" },
            { title: "任务模板详情" },
          ],
        }
      },
    ],
  },
  //单独app嵌入使用页面
  //列表页  平台数据
  {
    path: "/templateApp/list",
    component: () => import("@/views/template/List.vue"),
  },
  //和列表页关联的创建 平台数据
  {
    path: "/templateApp/details1/:id",
    component: () => import("@/views/template/Created.vue"),
  },
  //和列表页关联的创建 平台数据
  {
    path: "/templateApp/created1/:id",
    component: () => import("@/views/template/Created.vue"),
  },
  //单独不和列表页关联的创建 租户/应用使用
  {
    path: "/templateApp/details/:id",
    component: () => import("@/views/templateApp/Created.vue"),
  },
  //单独不和列表页关联的创建 租户/应用使用
  {
    path: "/templateApp/created/:id",
    component: () => import("@/views/templateApp/Created.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.state.isLogin === false) {
    afterLogout().then((res) => {
      if (res.data.success) {
        store.commit("changeisLogin", true);
        next();
      }
    });
  } else {
    next();
  }
});
export default router;
