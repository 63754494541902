const configModule = {
    namespaced: true,//开启命名空间 
    state: () => ({
        problemNum: 50,  //任务模板配置问题最大数量   页面调用(this as any).$store.state.config.problemNum
        optionNum: 10,//选择题最大数量 
        letter: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"],
    }),
    mutations: {
        //修改模块中的状态   页面调用(this as any).$store.commit('config/setTest',3)
        // setTest(state, test) {
        //     state.test = test;
        // }
    },
    actions: {},
    getters: {}
}
export default configModule