
import { defineComponent } from "vue";
import { Layout, Button, Menu } from "ant-design-vue";
import Content from "./Content.vue";
import { authc } from "@/api/sideBarApi";

import {
  BorderOutlined,
  FolderOpenFilled,
  FolderFilled,
  FolderOutlined,
  LinkOutlined,
} from "@ant-design/icons-vue";

const { Item, SubMenu } = Menu;
const { Sider } = Layout;

export default defineComponent({
  components: {
    Layout,
    Button,
    Sider,
    Content,
    Item,
    Menu,
    SubMenu,
    BorderOutlined, //空心方块
    FolderOpenFilled, //黑色文件夹
    FolderFilled, //黑色打开文件夹
    FolderOutlined, //白色文件夹
    LinkOutlined,
  },
  data() {
    return {
      //初始选中和展开数组
      openKeys: [],
      list: [],
      menuHeight: "300px",
      selectedKeys: [],
      showSider: true,
      authcList: [],
    };
  },
  methods: {
    handleClick(item) {
      if (item.isExternalLinks) {
        window.open(item.path, "_self");
      } else {
        (this as any).$router.push(item.path);
      }
      // this.selectedKeys = ["/taskHall/singleProduct/parameter"];
    },
    titleClick(item) {
      item.open = !item.open;
    },
    //展开关闭触发
    openChange(openKeys) {},
    //将后端获取的侧边栏 以及后添加的最外级菜单 拼接到我们系统上
    handleList(list) {
      let sidebar;
      (this as any).$router.getRoutes().forEach((ele) => {
        if (ele.path === "/") {
          return (sidebar = ele);
        }
      });
      (this as any).list = [...list, ...this.list, ...sidebar.children];
    },
    //获取后端传过来的侧边栏
    getAppList() {
      let list: any = [];
      (this as any).$store.state.sideBarApp.forEach((element) => {
        let item: any = {};
        item.name = element.meta.title;
        item.path = element.path;
        item.isExternalLinks = element.meta.isExternalLinks;
        item.meta = { hidden: false, title: element.meta.title };
        list.push(item);
      });
      this.handleList(list);
    },
    //从路由获取打开的侧边栏
    getOpenKeys() {
      let array = this.$route.matched.map((item) => {
        return item.path;
      });
      return array;
    },
    //获取权限
    getAuthc() {
      // console.log((this as any).list);
      let params = {};
      let list = (this as any).list[(this as any).list.length - 1].children;
      function open(list) {
        list.forEach((element) => {
          if (element.meta) {
            if (element.meta.key) {
              params[element.meta.key] = {
                method: "GET",
                path: element.path,
              };
            }
          }
          if (element.children) {
            open(element.children);
          }
        });
      }
      open(list);
      authc(params)
        .then((res: any) => {
          this.authcList = res.data.data;
          this.showSider = true;
        })
        .catch(() => {
          //待注释
          this.showSider = true;
        });
    },
  },
  created() {
    this.menuHeight = document.body.clientHeight - 120 + "px";
    let sidebar;
    (this as any).$router.getRoutes().forEach((ele) => {
      if (ele.path === "/taskHall") {
        return (sidebar = ele);
      }
    });
    // function dataHandle(data) {
    //   data.key = data.path;
    //   for (let j = 0; j < data.children.length; j++) {
    //     if (data.children[j].children && data.children[j].children.length > 0) {
    //       dataHandle(data.children[j]);
    //     } else {
    //       data.key = data.path;
    //     }
    //   }
    // }
    // dataHandle(sidebar);

    (this as any).list = [sidebar];
    (this as any).openKeys = this.getOpenKeys();
    this.getAppList();
    // this.getAuthc();
  },
  watch: {
    selectedKeys() {
      // console.log(this.selectedKeys);
    },
  },
});
