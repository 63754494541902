import { getDetails, getDetailsApp } from "@/api/template/api.js";

const templateModule = {
    namespaced: true,//开启命名空间 
    state: () => ({
        checkPublishPort: (code) => {
            switch (code) {
                case "CTT000ACTIVITY":
                    return "MIKA"
                case "CTT000EXPANDTASK":
                    return "PXTZT"
                case "CTT000ADDSTORE":
                    return "PXTZT"
            }
        },
        checkPerformPort: (code) => {
            switch (code) {
                case "CTT000ACTIVITY":
                    return "UDEFINED"
                case "CTT000EXPANDTASK":
                    return "PXTZT"
                case "CTT000ADDSTORE":
                    return "PXTZT"
            }
        },
        templateFormState: {},
        //获取详情并更新
        getNewTemplateFormState(id, type = '') {
            if (type === 'app' || location.pathname.includes('/templateApp/created/') || location.pathname.includes('/template/created/') || location.pathname.includes('/template/details/')) {
                return new Promise((resolve, reject) => {
                    getDetailsApp(id).then((res) => {
                        if (res.data.success) {
                            resolve(res.data.data)
                        } else {
                            resolve(false)
                        }
                    })
                })
            }
            else {
                return new Promise((resolve, reject) => {
                    getDetails(id).then((res) => {
                        if (res.data.success) {
                            resolve(res.data.data)
                        } else {
                            resolve(false)
                        }
                    })
                })
            }
        }
    }),
    mutations: {
        templateFormState(state, data) {
            state.templateFormState = data;
        },
    },
    actions: {},
    getters: {}
}
export default templateModule